import React, { Component, Fragment, useEffect, useState } from "react"
import { Formik } from "formik"
import axios from "axios"
import ru from "react-phone-input-2/lang/ru.json"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import "./style.sass"
import Select from "react-select"
import {
  OFFICE_OPTIONS,
  PLACE_OPTIONS,
  WINDOW_GLOBAL,
} from "../../helpers/constants.ts"
import { useLocation } from "@reach/router"

const formatOptionLabel = ({ value, label, price }) => (
  <div className="office-form-options">
    <div className="office-form-options__label">{label}</div>
    <div className="office-form-options__price">{price} р</div>
  </div>
)

const FormOffice = ({ link }) => {
  const [ready, setReady] = useState(false)
  const [office, setOffice] = useState(OFFICE_OPTIONS[0])
  const [placement, setPlacement] = useState(PLACE_OPTIONS[0])
  const [people, setPeople] = useState({ value: 1, label: 1 })
  const [invoiceId, setInvoiceId] = useState("")
  const [successMessage, setSuccessMessage] = useState(false)
  const [isDisabled, setDisabled] = useState(false)
  const [initialValues, setInitialValues] = useState({
    name: "",
    phone: "",
    company: "",
    inn: "",
    email: "",
    office: "",
    placement: "",
    people: 1,
    price: "",
  })
  const location = useLocation()

  const changeOptionsValue = type => {
    return PLACE_OPTIONS.filter(option => option.type === type)
  }

  const optionsPeople = max => {
    let options = []

    for (var i = 1; i <= max; i++) {
      options.push({ value: i, label: i })
    }
    return options
  }

  const onSubmit = data => {
    const formType = localStorage.getItem("tarif") || "fastStart"
    setDisabled(true)
    axios
      .post(`https://billing.sk.w6p.ru/api/v1/orders`, {
        total_order_cost: data.price,
        roistat_id: "",
        payment_method: "card",
        source: "smartoffice",
        formGroup: formType,
        company: {
          contact_name: data.name,
          inn: data.inn,
          email: data.email.toLowerCase(),
          tel: data.phone,
          name: data.company,
        },
        products: [
          {
            name: data.office,
            duration: data.placement,
            age_group: data.people,
            person_in_group: data.people,
            extended_excursion: 1,
            ticket_price: data.price / data.people,
          },
        ],
      })
      .then(async res => {
        if (res.data.data) {
          setSuccessMessage(true)
          setDisabled(false)
          window.ym && window.ym(62628037, "reachGoal", "rate")

          window.ga("send", "event", {
            eventCategory: "smart_office",
            eventAction: "send_office",
          })

          // await setInvoiceId(res.data.data.id)
          // payCard(data, res.data.data.id);
        }
      })
      .catch(e => {
        console.log(e)
        setDisabled(false)
      })
  }

  const validate = values => {
    let errors = {}

    if (!values.name) {
      errors.name = "Поле не может быть пустым"
    }

    if (!values.phone) {
      errors.phone = "Поле не может быть пустым"
    } else if (!/(\+?\d[- .]*){7,13}/i.test(values.phone)) {
      errors.phone = "Введен некорректный номер телефона"
    }
    if (!values.email) {
      errors.email = "Поле не может быть пустым"
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i.test(values.email)
    ) {
      errors.email = "Введен некорректный Email"
    }

    return errors
  }

  const payCard = (formData, orderId) => {
    const cpWidget = WINDOW_GLOBAL.cp
    let data = {}
    data.cloudPayments = {
      customerReceipt: {
        Items: [
          {
            label: formData.office,
            price: parseInt(formData.price).toFixed(2),
            quantity: +formData.people,
            vat: 20,
            amount: parseInt(placement.price).toFixed(2),
          },
        ],
        email: formData.email.toLowerCase(),
        phone: formData.phone,
        amounts: {
          electronic: parseInt(formData.price).toFixed(2),
        },
      },
    }
    var widget = new cpWidget.CloudPayments()
    widget.pay(
      "charge", // или 'charge'
      {
        // options
        publicId: "pk_a64d78cecc96a7e4fa68667367c7a", //id из личного кабинета
        description: formData.office, //назначение
        amount: formData.price, //сумма
        currency: "RUB", //валюта
        invoiceId: orderId, //номер заказа  (необязательно)
        accountId: formData.email.toLowerCase(), //идентификатор плательщика (необязательно)
        skin: "modern", //дизайн виджета
        data: data,
      },
      {
        onSuccess: function(options) {
          // success
          //действие при успешной оплате И ЗАКРЫТИИ ОКНА ВИДЖЕТА
        },
        onFail: function(reason, options) {
          // fail
          //действие при неуспешной оплате
          // fail

          axios
            .post(
              `https://billing.sk.w6p.ru/api/v1/change-status-cloud-payment`,
              {
                status: "ErrorPayment",
                InvoiceId: orderId,
              }
            )
            .then(res => {
              console.log(res)
            })
            .catch(e => console.log(e))
        },
        onComplete: function(paymentResult, options) {
          if (paymentResult.success) {
            // success
            axios
              .post(
                `https://billing.sk.w6p.ru/api/v1/change-status-cloud-payment`,
                {
                  status: "paid",
                  InvoiceId: orderId,
                  formGroup: "fastStart",
                }
              )
              .then(res => {
                console.log(res)
              })
              .catch(e => console.log(e))
            setSuccessMessage(true)
          } else {
            // fail

            axios
              .post(
                `https://billing.sk.w6p.ru/api/v1/change-status-cloud-payment`,
                {
                  status: "ErrorPayment",
                  InvoiceId: orderId,
                }
              )
              .then(res => {
                console.log(res)
              })
              .catch(e => console.log(e))
          }
        },
      }
    )
  }

  const init = () => {
    const search = link || location.search
    const tariffArray = search
      .split("&")
      .map(item => item.split("="))
      .map(item => item[1])
    const tariff = {
      type: tariffArray[0],
      place: tariffArray[1],
    }

    const currentOffice = OFFICE_OPTIONS.find(item => item.id === tariff.type)
    const currentPlacement = PLACE_OPTIONS.find(
      item => item.id === tariff.place && item.type === currentOffice.value
    )

    setOffice(currentOffice || OFFICE_OPTIONS[0])
    setPlacement(currentPlacement || PLACE_OPTIONS[0])
    setInitialValues(prev => ({
      ...prev,
      office: currentOffice?.value,
      placement: currentPlacement?.label,
      price: +currentPlacement?.price * +people.value,
    }))
    setReady(true)
  }

  useEffect(() => {
    init()
  }, [link]) // Add link to the dependency array

  return (
    <Fragment>
      {ready && (
        <div className="smart-office">
          <div className="office-form__title">Заявка на смарт-офис</div>
          <div className="smart-office__grid">
            <div className="smart-office__col">
              <div
                className={`office-form__wr ${
                  successMessage ? "success" : ""
                } `}
              >
                {successMessage ? (
                  <div className="order-page-desc__title">
                    <p>
                      Менеджер свяжется с вами в ближайшее время для уточнения
                      информации по бронированию.
                    </p>
                  </div>
                ) : (
                  <Formik
                    initialValues={initialValues}
                    validate={validate}
                    validateOnChange={false}
                    validateOnBlur
                    onSubmit={(values, actions) => {
                      actions.setSubmitting(false)
                      onSubmit(values)
                    }}
                  >
                    {props => (
                      <form
                        onSubmit={props.handleSubmit}
                        className="office-form"
                      >
                        <div className="office-form__fields-title">
                          Данные клиента
                        </div>

                        <div className="office-form__field">
                          <div className="office-form__label">Имя</div>
                          <div className="office-form__input">
                            <input
                              type="text"
                              value={props.values.name}
                              name="name"
                              onChange={props.handleChange}
                            />
                            {props.errors.name && props.touched.name ? (
                              <span className="help-block error">
                                {props.errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="office-form__field">
                          <div className="office-form__label">Компания</div>
                          <div className="office-form__input">
                            <input
                              type="text"
                              value={props.values.company}
                              name="company"
                              onChange={props.handleChange}
                            />
                            {props.errors.company && props.touched.company ? (
                              <span className="help-block error">
                                {props.errors.company}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="office-form__field">
                          <div className="office-form__label">ИНН</div>
                          <div className="office-form__input">
                            <input
                              type="number"
                              value={props.values.inn}
                              name="inn"
                              onChange={props.handleChange}
                            />
                            {props.errors.inn && props.touched.inn ? (
                              <span className="help-block error">
                                {props.errors.inn}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="office-form__field">
                          <div className="office-form__label">Телефон</div>
                          <div className="office-form__input">
                            <PhoneInput
                              country={"ru"}
                              enableAreaCodes={true}
                              value={props.values.phone}
                              localization={ru}
                              onChange={value =>
                                props.setFieldValue("phone", value)
                              }
                              enableSearch={true}
                              searchPlaceholder="Поиск"
                              placeholder="(999) 999-99-99"
                            />
                            {props.errors.phone && props.touched.phone ? (
                              <span className="help-block error">
                                {props.errors.phone}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="office-form__field">
                          <div className="office-form__label">E-mail</div>
                          <div className="office-form__input">
                            <input
                              name="email"
                              type="email"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.email}
                            />
                            {props.errors.email && props.touched.email ? (
                              <span className="help-block error">
                                {props.errors.email}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="office-form__fields-title">
                          Информация о тарифе
                        </div>

                        <div className="office-form__field">
                          <div className="office-form__label">Кол-во мест</div>
                          <div className="office-form__input">
                            <Select
                              className="period-select"
                              classNamePrefix="select"
                              value={people}
                              onChange={selectedOption => {
                                setPeople(selectedOption)
                                props.setFieldValue(
                                  "people",
                                  selectedOption.value
                                )
                                props.setFieldValue(
                                  "price",
                                  +placement.price * +selectedOption.value
                                )
                              }}
                              options={optionsPeople(placement?.maxPeople)}
                              name="people"
                              placeholder="Выберите из списка"
                              isSearchable={false}
                            />
                            {props.errors.people && props.touched.people ? (
                              <span className="help-block error">
                                {props.errors.people}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        {placement.price && (
                          <div className="office-form__field">
                            <div className="office-form__label">
                              <div className="office-form__price-title">
                                Итого к оплате:
                              </div>
                            </div>
                            <div className="office-form__input">
                              <div className="office-form__price">
                                {(
                                  +placement.price * +people.value
                                ).toLocaleString("ru")}{" "}
                                руб
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="form__text">
                          Нажимая кнопку Отправить, я&nbsp;выражаю согласие
                          на&nbsp;
                          <a
                            href="https://docs.sk.ru/category/passport/personal"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            обработку персональных данных
                          </a>
                        </div>
                        <button
                          className="button"
                          type="submit"
                          disabled={isDisabled}
                        >
                          <span>Оставить заявку</span>
                        </button>
                      </form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
            <div className="smart-office__col">
              <div className="smart-office__img">
                <img src="/images/payment1.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default FormOffice
