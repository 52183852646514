export const TARIFFS = [
  {
    small: true,
    title: "Hot Desk",
    subtitle: `Несколько компаний в одном офисе`,
    plan: [
      {
        title: "Нефиксированное рабочее место",
        price: "17 000",
        value: "₽/мес",
        about: `1 рабочее место в месяц <span>вкл. НДС</span>`,
        link: "?tariff=hot&place=unlimited",
      },
    ],
  },
  {
    small: false,
    title: "Shared Office",
    subtitle: `Несколько компаний в одном офисе`,
    plan: [
      {
        title: "До 10 сотрудников",
        price: "24 000",
        value: "₽/мес",
        about: `1 рабочее место в месяц <span>вкл. НДС</span>`,
        link: "?tariff=shared&place=atrium",
      },
      {
        title: "До 14 сотрудников",
        price: "25 000",
        value: "₽/мес",
        about: `1 рабочее место в месяц <span>вкл. НДС</span>`,
        link: "?tariff=shared&place=street",
      },
    ],
  },
  {
    small: false,
    title: "Private Office",
    subtitle: `Офис на одну компанию`,
    plan: [
      {
        title: "До 10 сотрудников",
        price: "26 000",
        value: "₽/мес",
        about: `1 рабочее место в месяц <span>вкл. НДС</span>`,
        link: "?tariff=private&place=atrium",
      },
      {
        title: "До 14 сотрудников",
        price: "27 000",
        value: "₽/мес",
        about: `1 рабочее место в месяц <span>вкл. НДС</span>`,
        link: "?tariff=private&place=street",
      },
    ],
  },
]
export const OFFICE_OPTIONS = [
  { value: "default", label: "" },
  { value: "Hot Desk", label: "Hot Desk", id: "hot" },
  { value: "Private Office", label: "Private Office", id: "private" },
  { value: "Shared Office", label: "Shared Office", id: "shared" },
]

export const PLACE_OPTIONS = [
  {
    value: "Нефиксированное рабочее место",
    label: "Нефиксированное рабочее место",
    price: "16200",
    type: "Hot Desk",
    maxPeople: "14",
    id: "unlimited",
  },
  {
    value: "Окно в атриум",
    label: "Окно в атриум",
    price: "25000",
    type: "Private Office",
    maxPeople: "10",
    id: "atrium",
  },
  {
    value: "Окно на улицу",
    label: "Окно на улицу",
    price: "26000",
    type: "Private Office",
    maxPeople: "14",
    id: "street",
  },
  {
    value: "Окно в атриум",
    label: "Окно в атриум",
    price: "23000",
    type: "Shared Office",
    maxPeople: "10",
    id: "atrium",
  },
  {
    value: "Окно на улицу",
    label: "Окно на улицу",
    price: "24000",
    type: "Shared Office",
    maxPeople: "10",
    id: "street",
  },
]

export const TARIFFS_COWORK = {
  guest: {
    link: "?for=guest",
    data: [
      {
        time: "Нефиксированное",
        price: "10 000₽",
        icon: "",
      },
      {
        time: "Нефиксированное с юридическим адресом",
        price: "18 500₽",
        icon: "",
      },
      {
        time: "Фиксированное с юридическим адресом",
        price: "24 500₽",
        icon: "",
      },
    ],
  },
  notLegalEntities: {
    link: "?for=guest",
    data: [
      {
        time: "Нефиксированное",
        price: "25 800₽",
        icon: "",
      },
    ],
  },
  participant: {
    link: "?for=participant",
    data: [
      {
        time: "1 день",
        price: "1 900₽",
        icon: "",
      },
      {
        time: "Неделя",
        price: "8 350₽",
        icon: "",
      },
    ],
  },
  participantLegal: {
    link: "?for=participant-legal",
    data: [
      {
        time: "Нефиксированное рабочее место",
        price: "17 000₽",
        icon: "/images/coworking/working.svg",
      },
      {
        time: "Фиксированное рабочее место",
        price: "22 500₽",
        icon: "/images/coworking/workplace.svg",
      },
    ],
  },
}

export const PERIODS = [
  {
    value: "2 часа",
    label: "2 часа",
    price: "690",
    type: "guest",
  },
  { value: "Сутки", label: "Сутки", price: "1800", type: "guest" },
  { value: "Неделя", label: "Неделя", price: "8000", type: "guest" },
  { value: "Месяц", label: "Месяц", price: "23800", type: "guest" },
  { value: "Сутки", label: "Сутки", price: "900", type: "participant" },
  { value: "Неделя", label: "Неделя", price: "3660", type: "participant" },
  { value: "Месяц", label: "Месяц", price: "9150", type: "participant" },
  {
    value: "5 будних дней в месяц",
    label: "5 будних дней в месяц",
    price: "5600",
    type: "",
    promo: "1",
  },
  {
    value: "12 дней в месяц",
    label: "12 дней в месяц",
    price: "13300",
    type: "",
    promo: "2",
  },
  {
    value: "2 места в месяц",
    label: "2 места в месяц",
    price: "35350",
    type: "",
    promo: "3",
  },
]

export const WINDOW_GLOBAL = typeof window !== "undefined" && window
